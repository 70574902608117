<template>
    <div class="relative flex justify-center">
        <div ref="parent" class="relative flex justify-end items-center w-full">
            <input :disabled="isDisabled" @focus="onFocus" @keydown="onSearch" @keydown.down.prevent="arrowDown" @keydown.up.prevent="arrowUp" @keydown.enter.prevent="onEnter" ref="search" v-model="search" type="text" class="h-9 w-full focus:outline-none pl-2 pr-5 cursor-pointer truncate bg-black border border-gray-700 rounded-sm" :placeholder="placeholder">
            <span class="absolute right-1.5 bg-gray-800 px-1.5 py-0.5 rounded-sm text-gray-400">
                ⌘ K
            </span>
        </div>
        <ul ref="dropdown" v-show="show && !isDisabled" class="absolute z-20 top-9 bg-gray-800 mt-px border border-gray-800 rounded-b-sm shadow-lg max-h-96 overflow-auto">
            <li v-for="(item, i) in items" :key="i" :ref="'list'+i" @click="onSelect(i)" @mouseover="onMouseOver(i)" @mouseleave="onMouseLeave" class="px-2 py-2 cursor-pointer transition duration-200" :class="{'bg-gray-700': i === rowCount}">
                <slot :item="item"></slot>
            </li>
            <li @click="onShowModalProduct" class=" sticky bottom-0 py-2 border-t border-gray-700 bg-gray-800 px-10 text-teal-500 transition duration-200 hover:bg-gray-700 cursor-pointer">Search More...</li>
        </ul>

        <!-- MODAL PRODUCT -->
        <modal :show="showModalProducts">
            <div class=" w-2/3 bg-white border rounded-sm cursor-default text-gray-600">
                <div class="pl-5 pr-3 py-3 border-b flex items-center justify-between">
                    <div class="font-semibold">SEARCH PRODUCT</div>
                    <a @click.prevent="showModalProducts = false" href="#"><i class="icon-cross3 text-gray-500 hover:text-red-500" style="font-size: 19px"></i></a>
                </div>
                <div ref="modalBody" class="overflow-scroll">
                    <div class="flex justify-between items-center px-5 py-2">
                        <div class="relative flex items-center">
                            <input v-model="searchProduct" ref="searchProduct" type="text" class="border-b focus:outline-none w-96 py-1 pr-6 focus:border-teal-500" placeholder="Search product name or SKU...">
                            <span v-show="!searchProduct" class="absolute right-0 text-gray-400"><i class="icon-search4"></i></span>
                            <a v-show="searchProduct" @click.prevent="searchProduct=''" href="#" class="text-red-500 absolute right-0"><i class="icon-cross3"></i></a>
                        </div>
                        <div class="flex items-center">
                            <div class="flex items-center">
                                <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                                <div class="flex items-center space-x-1">
                                    <button @click="getProduct(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                                    <button @click="getProduct(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table class="w-full">
                        <thead>
                            <tr class="border-t border-b">
                                <th class="pl-5 py-2" style="width: 50%">Product name</th>
                                <th class="px-3 py-2 text-right" style="width: 15%">Unit Price</th>
                                <th class="pl-3 pr-5 py-2 text-right" style="width: 10%">Stock</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y">
                            <tr v-for="(product, i) in products" :key="i" @click="onSelectItem(product)" class="hover:bg-gray-100 cursor-pointer">
                                <td class="pl-5 py-2">
                                    <div class="flex items-center">
                                        <img class="border rounded-sm" :src="imgURL+'/public/img/products/200/'+product.images[0]" width="40" alt="">
                                        <div class="ml-3">
                                            <div class="font-medium">{{product.name}}</div>
                                            <div class="text-gray-400">{{product.sku}}</div>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-3 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(product.sellingPrice)}}</td>
                                <td class="pl-3 pr-5 text-right">{{product.stock}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash.debounce'
import Modal from '../components/Modal.vue'
export default {
    props: ['value','placeholder','text', 'params', 'err', 'label', 'hover', 'isDisabled'],
    components: {Modal},
    data () {
        return {
            url: '/products/auto-search',
            show: false,
            isLoading: false,
            itemName: '',
            search: '',
            items: '',
            rowCount: 0,
            item: '',
            imgURL: process.env.VUE_APP_ROOT_API,
            showModalProducts: false,
            searchData: '',
            searchProduct: '',
            searchDataProduct: '',
            products: '',
            pages: '',
            onFocusSearch: false
        }
    },
    created() {
        this.searchData = debounce(() => {
            this.getData()
        }, 300)
        this.searchDataProduct = debounce (() => {
            this.getProduct()
        }, 200)
    },
    mounted () {
        let w = this.$refs.parent.clientWidth
        this.$refs.dropdown.style.width = w+4+'px'
        let h = window.innerHeight - 110
        this.$refs.modalBody.style.height = h+'px'
        window.addEventListener('click', this.closeMenu)
        window.addEventListener('keydown', this.escapeKey)
        window.addEventListener('keydown', this.onKeyDown)
        window.addEventListener('keyup', this.onKeyUp)
        window.addEventListener('keydown', this.onCombine)
        this.search = this.label
    },
    destroyed () {
        window.removeEventListener('click', this.closeMenu)
        window.removeEventListener('keydown', this.escapeKey)
        window.removeEventListener('keydown', this.onKeyDown)
        window.removeEventListener('keyup', this.onKeyUp)
        window.removeEventListener('keydown', this.onCombine)
    },
    methods: {
        onGetData () {
            this.isLoading = true
            axios.get(this.url, {
                params: {
                    notin: this.params
                }
            })
            .then((res) => {
                this.isLoading = false
                this.items = res.data
                this.rowCount = 0
                if(res.data.length > 0) {
                    this.show = true
                    this.$nextTick(() => {
                        this.$refs.dropdown.scrollTop = 0
                    })
                }
            })
            .catch(() => {
                this.isLoading = false
            })
        },
        onSearch (e) {
            if(e.keyCode !== 40 && e.keyCode !== 38 && e.keyCode !== 13 && e.keyCode !== 27) {
                this.searchData()
            }
        },
        getData () {
            this.isLoading = true
            axios.get(this.url, {
                params: {
                    search: this.search,
                    notin: this.params
                }
            })
            .then((res) => {
                this.isLoading = false
                this.items = res.data
                this.rowCount = 0
                if(res.data.length > 0) {
                    this.show = true
                }
            })
            .catch(() => {
                this.isLoading = false
            })
        },
        onFocus () {
            this.onGetData()
        },
       
        onSelect (i) {
            this.$emit('change', this.items[i])
            this.$emit('input', this.items[i]._id)
            this.itemName = this.items[i].name
            this.show = false
            this.search = ''
            this.$refs.search.blur()
        },  
        onEnter () {
            if(this.rowCount >= 0 && this.show && this.items.length > 0) {
                this.$emit('change', this.items[this.rowCount])
                this.$emit('input', this.items[this.rowCount]._id)
                this.itemName = this.items[this.rowCount].name
                this.show = false
                this.search = ''
                this.$refs.search.blur()
            }
        },
        onMouseOver (i) {
            this.rowCount = i
        },
        onMouseLeave () {
            this.rowCount = 0
        },
        arrowDown () {
            if(this.rowCount < this.items.length && this.items.length > 0 && this.show) {
                const menuHeight = this.$refs.dropdown.clientHeight
                const scrollTop = this.$refs.dropdown.scrollTop
                this.rowCount = this.rowCount +1
                this.$nextTick(() => {
                    this.search = this.items[this.rowCount].name
                    const top = this.$refs['list'+this.rowCount][0].offsetTop
                    const height = this.$refs['list'+this.rowCount][0].getBoundingClientRect()
                    if((top+height.height) > (menuHeight + scrollTop)) {
                        this.$refs.dropdown.scrollTop = (top + height.height) - menuHeight
                    }
                })
            }
            if(this.rowCount > this.items.length-1 && this.show) {
                this.rowCount = 0
                this.$refs.dropdown.scrollTop = 0
            }
        },
        arrowUp () {
            if(this.rowCount > 0 && this.show) {
                const menuHeight = this.$refs.dropdown.scrollTop
                this.rowCount = this.rowCount -1
                this.$nextTick(() => {
                    this.search = this.items[this.rowCount].name
                    const top = this.$refs['list'+this.rowCount][0].offsetTop
                    if(top < menuHeight) {
                        this.$refs.dropdown.scrollTop = top
                    }
                })
            }
        },
        closeMenu (e) {
            if(!e || !this.$el.contains(e.target)) {
                if(!this.value) {
                    this.search = ''
                }
                this.show = false
                this.items = ''
                this.item = ''
            }
        },

        // MODAL PRODUCT FUNCTION
        onShowModalProduct () {
            this.getProduct()
            this.showModalProducts = true
            this.show = false
            this.$nextTick(() => {
                this.$refs.searchProduct.focus()
            })
        },
        getProduct (e) {
            axios.get('/products/search', {
                params: {
                    search: this.searchProduct,
                    page: e
                }
            })
            .then(res => {
                this.products = res.data.data
                this.pages = res.data.pages
            })
        },
        onSelectItem (p) {
            this.$emit('change', p)
            this.$emit('input', p._id)
            this.itemName = p.name

            this.showModalProducts = false
        },
        escapeKey (e) {
            if(e.keyCode == 27) {
                this.$refs.search.blur()
                this.$nextTick(() => {
                    this.closeMenu()
                })
            }
        },
        onKeyDown (e) {
            if(e.keyCode === 91 || e.keyCode == 17) {
                this.onFocusSearch = true
            }
        },
        onKeyUp (e) {
            if(e.keyCode === 91 || e.keyCode == 17) {
                this.onFocusSearch = false
            } 
        },
        onCombine (e) {
            if(this.onFocusSearch && e.keyCode == 75) {
                this.$refs.search.focus()
            }
        }
    },
    watch: {
        value (val) {
            if(val) {
                if(this.text && this.items) {
                    this.search = this.itemName
                } else {
                    this.search = '' || this.label
                }
            }
        },
        search (val) {
            if(!val) {
                this.$emit('input', '')
            }
        },
        searchProduct () {
            this.searchDataProduct()
        }
     },
}
</script>